import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";

export const useScroll = (limit) => {
  const [state, setState] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      setState(window.scrollY > limit);
    };
  }, [limit]);

  return state;
};

const ScrollTop = () => {
  const visible = useScroll(400);

  return (
    <div
      style={{
        bottom: visible ? "2%" : "-50px",
        left: "2%",
        transition: "500ms",
      }}
      className="fixed transition duration-200"
    >
      <a href="#top">
        <div className="z-50 border-4 border-primary text-primary hover:border-secondary hover:text-secondary p-2  rounded-full">
          <FaArrowUp size={25} />
        </div>
      </a>
    </div>
  );
};

export default ScrollTop;
