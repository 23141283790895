import axios from "axios";
import config from "./config";

const api = axios.create({
  baseURL: config.api_url,
  responseType: "json",
});

api.interceptors.response.use(
  (response) => {
    if (!response?.data?.success)
      throw new Error(response.data.error || "Une erreur est survenue.");

    return response;
  },
  (error) => {
    throw new Error(error.response?.data?.error || "Une erreur est survenue.");
  }
);

export const send = (data) => {
  return api.post("/", data);
};
