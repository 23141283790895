import { Layout } from "./components/layout";
import Formulaire from "./components/Formulaire";
import Carrousel from "./components/Carrousel";
import ScrollTop from "./components/common/ScrollTop";
import Step from "./components/common/Step";

import chariot from "./assets/chariot-cartons-stockage-dans-un-box.svg";
import besoin from "./assets/besoin-de-stockage-a-nimes.svg";
import gratuit from "./assets/comparateur-gratuit-box-stockage.png";
import devis from "./assets/devis-sans engagement-a-nimes.png";
import one from "./assets/01-reception-demande-box-de-stockage-nimes.png";
import two from "./assets/02-etude-de-demande.png";
import three from "./assets/03-envoi-devis-pour-un-box.png";

const App = () => {
  return (
    <Layout>
      <div className="w-full m-auto" style={{ maxWidth: "1200px" }}>
        <div className="xl:px-16 md:px-10 px-2">
          <div className="inline-flex justify-around flex-wrap w-full">
            <div
              id="formulaire"
              className="w-full px-4"
              style={{ maxWidth: "450px" }}
            >
              <h1 className="text-3xl text-center text-primary font-semibold tracking-wider">
                Trouver votre{" "}
                <span className="text-secondary"> box de stockage </span> sur
                Montpellier
              </h1>

              <Formulaire />

              <div className="flex justify-around my-4 w-full">
                <div className="flex justify-between">
                  <img
                    src={devis}
                    style={{ width: 47, height: 40 }}
                    alt="devis"
                  />
                  <p className="mx-4 text-primary text-xs my-auto">
                    Devis sans <br /> engagement
                  </p>
                </div>
                <div className="flex justify-between">
                  <img src={gratuit} width={50} alt="gratuit" />
                  <p className="mx-4 text-primary text-xs my-auto">
                    Comparateur <br /> 100% gratuit
                  </p>
                </div>
              </div>
            </div>

            <div className="my-auto">
              <img src={besoin} width={450} height={500} alt="illustration" />
            </div>
          </div>
        </div>

        <div id="fonctionnement" className="mx-auto w-full">
          <div className="text-center mt-28 w-11/12 laptop:w-3/4 mx-auto">
            <h2 className="text-primary text-2xl laptop:text-3xl font-semibold">
              Comment box-de-stockage Montpellier fonctionne
            </h2>
          </div>
          <div className="w-full mx-auto flex justify-around flex-wrap my-10">
            {[
              {
                text: "Nos conseillers reçoivent votre demande",
                img: one,
              },
              {
                text: "Ils étudient votre demande et recherchent dans leurs base de donnée les meilleurs box de stockage",
                img: two,
              },
              {
                text: "Ils vous envoient le meilleur tarif de box de stockage sur Montpellier selon vos besoins",
                img: three,
              },
            ].map((step, index) => (
              <Step key={index} number={`0${index + 1}`} {...step} />
            ))}
          </div>
        </div>

        <div
          className="relative w-full bg-clip-padding bg-center md:bg-left bg-no-repeat pt-10 pb-6"
          style={{
            backgroundImage: `url(${chariot})`,
            minHeight: "650px",
          }}
        >
          <div className="mx-auto">
            <h2 className="text-center text-primary text-3xl font-semibold">
              Votre box de stockage à Montpellier
            </h2>
          </div>
          <div className="laptop:absolute laptop:right-12 mx-auto bg-white bg-opacity-90 shadow-lg rounded-3xl w-11/12 laptop:w-3/4 laptop:right-28 px-8 py-2 my-10">
            <div className="my-4">
              <h3 className="text-secondary text-2xl text-center my-1">
                Un espace qui vous appartient
              </h3>
              <p className="text-justify text-sm laptop:text-md">
                <a
                  className="text-secondary hover:underline"
                  href="https://www.atout-box.fr"
                >
                  La location de box de stockage
                </a>{" "}
                vous donne accès à un espace qui vous appartient entièrement.
                Dès la signature de votre contrat vous pourrez accéder à votre
                box comme bon vous semble. En effet, les accès à un centre de
                stockage de nos partenaires sont illimités toute la journée.
                Vous aurez simplement à entrer un code d’accès qui désactivera
                l’alarme anti-intrusion de votre espace personnel. Vous vous
                demandez peut-être ce que vous pouvez stocker dans votre box. La
                réponse est simple : TOUT tant que vous ne portez pas préjudices
                aux autres locataires de box. Bref, vous pourrez mettre ce que
                vous voulez, quand vous le souhaitez.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-secondary text-2xl text-center my-1">
                Montpellier : une ville dynamique où il y fait bon vivre
              </h3>
              <p className="text-justify text-sm laptop:text-md">
                Ce n’est un secret pour personne, Montpellier est un ville qui
                attire chaque année un flux migratoire important, en témoignent
                les nombreux déménagements sur le territoire. Le soleil, la mer,
                l’architecture, les opportunités professionnelles… Les raisons
                pour s’installer à Montpellier et y développer son entreprise
                sont diverses et variées. L’attractivité du territoire attire
                les talents professionnels, ce qui peut être une vraie aubaine
                lors de la création de son entreprise. Si Montpellier est
                surnommée la surdouée, ce n’est pas par hasard. Elle connaît la
                croissance économique et démographique la plus forte du pays
                depuis 20 ans. Pour ces raisons, la demande de box de stockage
                est très élevée dans la région. Nous avons lancé ce projet de
                comparateur de box de stockage afin de pouvoir satisfaire cette
                demande grandissante. Notre souhait est de pouvoir proposer à
                tous ceux qui en ont le besoin : un espace de stockage
                supplémentaire à tarif réduit.
              </p>
            </div>
            <div className="text-center mt-12 mb-5">
              <a
                className="bg-primary font-semibold shadow hover:shadow-xl rounded-2xl text-white px-8 py-3"
                href="#formulaire"
              >
                Recevoir les tarifs
              </a>
            </div>
          </div>
        </div>
        <div id="criteres" className="w-full mt-16">
          <div className="text-primary text-center">
            <h2 className="text-3xl font-semibold">
              Nos critères de sélection
            </h2>
            <p>
              Afin de vous proposer le meilleur service possible, nous avons des
              critères stricts de sélection.
            </p>
          </div>
          <div className="mt-10">
            <Carrousel />
          </div>
        </div>
      </div>
      <ScrollTop />
    </Layout>
  );
};

export default App;
