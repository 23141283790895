import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import camion from "../assets/carousel/camion-offert-location-box-de-stockage.svg";
import access from "../assets/carousel/box-accessible-24h-24.svg";
import clean from "../assets/carousel/locaux-de-stockage-propres.svg";
import security from "../assets/carousel/securite-centre-stockage.svg";
import service from "../assets/carousel/service-client-montpellier.svg";

const Carrousel = () => {
  const options = {
    draggable: false,
    arrows: false,
    dots: true,
    slidesToShow: 3,
    centerMode: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    centerPadding: "10px",
    center: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const content = [
    {
      title: "Service client",
      text: "Nous choisissons uniquement des prestataires ayant un service client de qualité.",
      img: service,
    },
    {
      title: "7J/7",
      text: "Box accessible tout l'année (jours fériés et dimanche inclus)",
      img: access,
    },
    {
      title: "Camion offert",
      text: "Box accessible tout l'année (jours fériés et dimanche inclus)",
      img: camion,
    },
    {
      title: "Sécurité",
      text: "Un système de sécurité 24h/24 doté des dernières technologies.",
      img: security,
    },
    {
      title: "Locaux propres",
      text: "Les locaux sont propres et secs. Ce qui permet de récupérer ses affaires en parfait état, même plusieurs années plus tard.",
      img: clean,
    },
  ];

  return (
    <Slider {...options}>
      {content.map((item, index) => (
        <div key={index}>
          <div className="w-64 h-72 mx-auto bg-primary rounded-2xl py-5 px-5">
            <div className="mx-auto">
              <img className="m-auto h-32" src={item.img} alt={item.title} />
              <div className="align-middle text-white text-center">
                <h4 className="w-full text-xl font-bold mt-2">{item.title}</h4>
                <p className="text-center text-sm">{item.text}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Carrousel;
