import { useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaSpinner } from "react-icons/fa";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import Moment from "../helpers/moment";
import {
  FormGroup,
  Label,
  Input,
  CustomCheckbox,
  InputGroup,
} from "./common/Form";
import { send } from "../helpers/api";
import config from "../helpers/config";

toastr.options = {
  closeButton: true,
  progressBar: true,
  newestOnTop: true,
  positionClass: "toast-top-left",
};

const today = Moment().format("YYYY-MM-DD");

const Formulaire = () => {
  const form = useRef();
  const [isSubmitting, setSubmitting] = useState(false);

  const initialValues = {
    prenom: "",
    nom: "",
    email: "",
    phone: "",
    entree: "",
    options24h: false,
    optionsCamion: false,
  };

  const validationSchema = Yup.object().shape({
    prenom: Yup.string().max(200, "prenom trop long."),
    nom: Yup.string()
      .required("Veuillez remplir ce champ")
      .max(200, "nom trop long."),
    email: Yup.string()
      .email("Veuillez indiquer un email valide")
      .required("Veuillez indiquer un email")
      .max(200, "email trop long."),
    phone: Yup.string().required("Veuillez indiquer un téléphone valide"),
    entree: Yup.date(),
  });

  const handleSubmit = async (values) => {
    setSubmitting(true);
    const payload = new FormData();
    Object.keys(values).forEach((key) => payload.append(key, values[key]));

    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(config.catcha_key, { action: "submit" })
        .then(async (token) => {
          try {
            payload.append("token", token);

            await send(payload);
            toastr.success("Votre demande a bien été pris en compte.");
          } catch (error) {
            toastr.error(error.message || "Une erreur est survenue");
          }
          setSubmitting(false);
        });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form ref={form} onSubmit={handleSubmit}>
          <FormGroup className="flex justify-between my-3">
            <InputGroup>
              <Input
                type="text"
                name="prenom"
                value={values.prenom}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Prénom"
                touched={touched.prenom}
                error={errors.prenom}
                disabled={isSubmitting}
              />
            </InputGroup>
            <InputGroup>
              <Input
                type="text"
                name="nom"
                value={values.nom}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Nom *"
                required={true}
                touched={touched.nom}
                error={errors.nom}
                disabled={isSubmitting}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="my-3">
            <InputGroup>
              <Input
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Adresse email *"
                required={true}
                touched={touched.email}
                error={errors.email}
                disabled={isSubmitting}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="my-3">
            <InputGroup>
              <Input
                type="text"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Téléphone *"
                required={true}
                touched={touched.phone}
                error={errors.phone}
                disabled={isSubmitting}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="flex justify-between my-4">
            <InputGroup className="h-full my-auto">
              <Label required htmlFor="entree">
                Date d’entrée prévue{" "}
              </Label>
              <Input
                className="mt-3"
                id="entree"
                type="date"
                name="entree"
                placehoder="date"
                value={values.entree}
                required={true}
                onChange={handleChange}
                onBlur={handleBlur}
                min={today}
                touched={touched.entree}
                error={errors.entree}
                disabled={isSubmitting}
              />
            </InputGroup>
            <InputGroup>
              <p className="text-primary text-center">Options recherchées</p>
              <div className="cursor-pointer sm:ml-5">
                <CustomCheckbox
                  name="options24h"
                  value={values.options24h}
                  onChange={(value) => setFieldValue("options24h", value)}
                  onBlur={handleBlur}
                  label="Accès 24h/24"
                  className="m-1.5 cursor-pointer"
                  disabled={isSubmitting}
                />
                <CustomCheckbox
                  name="optionsCamion"
                  value={values.optionsCamion}
                  onChange={(value) => setFieldValue("optionsCamion", value)}
                  onBlur={handleBlur}
                  label="Camion offert"
                  className="m-1.5 cursor-pointer"
                  disabled={isSubmitting}
                />
              </div>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <div className="my-4 text-center">
              <p className="text-sm text-gray-600">
                Vous pourrez modifier les dates ultérieurement si besoin
              </p>
              <button
                type="submit"
                className="rounded-full bg-primary w-full py-3 text-white bold tracking-wide flex justify-center disabled:opacity-50"
                disabled={isSubmitting}
              >
                {!isSubmitting ? (
                  "Obtenir le meilleur tarif gratuitement"
                ) : (
                  <>
                    Chargement{" "}
                    <FaSpinner size={24} className="ml-4 animate-spin" />
                  </>
                )}
              </button>
              <p className="text-xs text-justify mx-3 mt-5 opacity-50 italic">
                En envoyant ce formulaire, j'accepte que les données envoyées
                soient utilisées dans le cadre de la demande de contact et de la
                relation commerciale qui peut en suivre.
              </p>
            </div>
          </FormGroup>
        </form>
      )}
    </Formik>
  );
};

export default Formulaire;
