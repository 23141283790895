import { FaBars } from "react-icons/fa";
import { useState } from "react";

import logo from "./../../assets/logoblanc.svg";

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <header id="top" className="mb-8">
      <div className="inline-flex justify-around bg-primary w-full">
        <div className="w-full h-full">
          <a href="/">
            <img src={logo} alt="logo" className="mx-auto px-1 my-2" />
          </a>
        </div>
        <div className="w-full flex">
          <ul className="hidden sm:flex flex justify-center my-auto w-full">
            <li className="mx-5 text-white text-md lg:text-lg font-semibold">
              <a
                href="#fonctionnement"
                className="hover:text-secondary transition cursor-pointer"
              >
                Le fonctionnement
              </a>
            </li>
            <li className="mx-5 text-white text-md lg:text-lg font-semibold">
              <a
                href="#criteres"
                className="hover:text-secondary transition cursor-pointer"
              >
                Nos critères
              </a>
            </li>
          </ul>
          <div className="relative flex justify-end sm:hidden h-full w-full">
            <button className="h-full mr-5 my-auto">
              <FaBars
                onClick={() => setOpen(!open)}
                onMouseEnter={() => setOpen(true)}
                className="text-white hover:text-secondary transition"
                size={30}
              />
            </button>

            {open ? (
              <div className="absolute top-5 right-3 transition">
                <div
                  onMouseLeave={() => setOpen(false)}
                  className="mt-2 w-52 text-center shadow-lg bg-white ring-1 ring-black ring-opacity-5 outline-none"
                >
                  <div onClick={() => setOpen(false)}>
                    <a
                      href="#fonctionnement"
                      className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 border-b"
                    >
                      Le fonctionnement
                    </a>
                    <a
                      href="#criteres"
                      className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 border-b"
                    >
                      Nos critères
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
