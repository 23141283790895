import classnames from "classnames";
import Checkbox from "react-custom-checkbox";
import { FaCheck } from "react-icons/fa";

export const FormGroup = ({ children, ...rest }) => {
  return <div {...rest}>{children}</div>;
};

FormGroup.defaultProps = {
  col: 1,
};

export const InputGroup = ({ children, className, ...rest }) => {
  return (
    <div className={classnames("w-full px-1", className)} {...rest}>
      {children}
    </div>
  );
};

export const Input = ({ className, touched, error, ...props }) => {
  const { children, style, ...rest } = props;

  let border =
    touched && !!error
      ? "border-danger hover:border-secondary"
      : "border-primary hover:border-secondary";

  return (
    <>
      <div
        className={`h-11 border-2 rounded-full px-4 w-full shadow-xl ${border} ${className}`}
      >
        <input
          {...rest}
          className="my-auto placeholder-black placeholder-opacity-70 rounded-2xl h-full w-full"
        >
          {children}
        </input>
      </div>
      {touched && !!error ? (
        <div className="text-danger text-center text-sm">{error}</div>
      ) : (
        <div className="invisible text-sm"> none </div>
      )}
    </>
  );
};

export const Label = ({ children, htmlFor, className, required, ...rest }) => {
  return (
    <label
      className="w-full text-center text-primary tracking-wide"
      htmlFor={htmlFor}
      {...rest}
    >
      {children} {required ? <em className="text-secondary"> * </em> : null}
    </label>
  );
};

export const CustomCheckbox = ({ value, name, label, ...rest }) => {
  return (
    <Checkbox
      name={name}
      checked={value}
      label={label}
      borderColor="#62688f"
      borderRadius={1}
      size={20}
      icon={<FaCheck color="#e35d5d" size={18} />}
      {...rest}
    />
  );
};
