const Step = ({ img, number, text }) => {
  return (
    <div className="relative my-6 w-1/3" style={{ minWidth: "350px" }}>
      <span
        className="bg-gray-200 p-6 rounded-3xl text-white"
        style={{ fontSize: "9em" }}
      >
        {number}
      </span>
      <img
        width={250}
        height={230}
        className="absolute top-0 right-0"
        src={img}
        alt={`étape-${number}`}
      />
      <p
        className="my-10 text-center mx-auto text-primary"
        style={{ maxWidth: "70%" }}
      >
        {text}
      </p>
    </div>
  );
};

export default Step;
